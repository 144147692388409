////////////////////////////////////////////////////////
// openModal
////////////////////////////////////////////////////////
export function openModal(id: string) {
  if (typeof document !== 'undefined') {
    const modal: any = document.getElementById(id)
    modal.showModal()
  }
}

////////////////////////////////////////////////////////
// closeModal
////////////////////////////////////////////////////////
export function closeModal(id: string) {
  if (typeof document !== 'undefined') {
    const modal: any = document.getElementById(id)
    modal.close()
  }
}

////////////////////////////////////////////////////////
// toggleModal
////////////////////////////////////////////////////////
export function toggleModal(id: string) {
  if (typeof document !== 'undefined') {
    const modal: any = document.getElementById(id)
    if (modal.hasAttribute('open')) {
      modal.close()
    } else {
      modal.showModal()
    }
  }
}
